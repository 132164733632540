<template>
  <div>
    <VueShowdown :markdown="text" flavor="github" :options="{ emoji: true }" />
    <div class="hidden" style="display: none">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostMardownView",
  data() {
    return {
      text: "",
    };
  },
  mounted() {
    this.text = this.$slots.default()[0].children;
  },
};
</script>

<style lang="scss" scoped></style>
