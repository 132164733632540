<template>
  <div
    class="post-segment"
    ref="segment"
    :class="[hasImage ? 'with-image' : '', hasText ? 'with-text' : '']"
    :style="{ height: segmentHeight, width: segmentWidth }">
    <div
      class="main-image-container"
      v-if="hasImage"
      :class="[hasText ? 'has-text' : '']">
      <img
        v-if="!isYoutube && !isVideo"
        class="main-image"
        :src="imageSrc"
        ref="mainimage"
        @click="showModal = !showModal"
        @load="imageLoaded = true" />
      <iframe
        class="main-image"
        v-if="isMounted && isYoutube"
        :width="getVideoWidth"
        :height="getVideoHeight"
        :src="imageSrc"
        title="YouTube video player"
        @load="imageLoaded = true"
        ref="mainvideo"
        frameborder="0"
        allow="accelerometer;  clipboard-write; encrypted-media; picture-in-picture"
        allowfullscreen></iframe>
      <video
        controls
        :src="imageSrc"
        ref="mainimage"
        v-if="isVideo"
        class="main-image"
        @load="imageLoaded = true"></video>
      <div class="backfill" v-if="showModal"></div>
      <img
        v-if="showModal"
        class="main-image fullscreen"
        :src="imageSrc"
        @click="showModal = !showModal" />
    </div>
    <div
      class="text-container"
      ref="textbox"
      :class="!hasImage ? 'no-image' : ''"
      v-if="hasText">
      <post-markdown-text class="main-text text" ref="maintext">{{
        text
      }}</post-markdown-text>
    </div>
  </div>
</template>

<script>
import stylevars from "../../styles/mixins/globals.scss";
import PostMarkdownText from "./PostMarkdownText.vue";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  components: { PostMarkdownText },
  name: "PostViewSegment",
  props: {
    image: String,
    text: String,
    externalURL: String,
  },
  mixins: [imageMixin],
  data() {
    return {
      showModal: false,
      imageLoaded: false,
      resizeAbortController: {},
      stylevars: stylevars,
      windowSize: 0,
      isMounted: false,
    };
  },
  computed: {
    hasImage() {
      return (this.image && this.image !== "") || this.externalURL;
    },
    isYoutube() {
      return this.externalURL && this.matchYoutube(this.externalURL);
    },
    isVideo() {
      return this.externalURL && this.matchVideo(this.externalURL);
    },
    hasText() {
      return this.text && this.text !== "";
    },
    segmentHeight() {
      if (!this.hasImage) {
        return "auto";
      }
      if (this.isMounted && (this.isYoutube || this.isVideo) && !this.hasText) {
        return this.getVideoHeight;
      }
      if (this.isMounted && (this.isYoutube || this.isVideo) && this.hasText) {
        if (document.body.clientWidth < parseInt(stylevars.mobileWidth)) {
          return (
            this.$refs.textbox.clientHeight +
            parseInt(this.getVideoHeight) +
            "px"
          );
        } else {
          return (
            Math.max(
              this.$refs.textbox.childNodes[0].clientHeight,
              parseInt(this.getVideoHeight)
            ) + "px"
          );
        }
      }
      if (this.imageLoaded && !this.hasText) {
        return (
          Math.min(
            this.$refs.mainimage.naturalHeight,
            document.body.clientHeight,
            this.$refs.mainimage.clientHeight
          ) + "px"
        );
      }
      if (this.imageLoaded && this.hasText) {
        if (document.body.clientWidth < parseInt(stylevars.mobileWidth)) {
          return (
            this.$refs.textbox.clientHeight +
            Math.min(
              this.$refs.mainimage.naturalHeight,
              document.body.clientHeight,
              this.$refs.mainimage.clientHeight
            ) +
            "px"
          );
        }
        return (
          Math.max(
            this.$refs.textbox.childNodes[0].clientHeight,
            Math.min(this.$refs.mainimage.naturalHeight, window.innerHeight) / 2
          ) + "px"
        );
      }
      return document.body.clientHeight + "px";
    },
    segmentWidth() {
      return "100%";
    },
    getVideoHeight() {
      return (parseInt(this.getVideoWidth) * 9) / 16 + "px";
    },
    getVideoWidth() {
      let maxWidth = this.$el.clientWidth * 0.7;
      if (window.innerHeight < (maxWidth * 9) / 16) {
        //to prevent yt videos being taller than the screen on widescreen displays
        maxWidth = ((window.innerHeight / 9) * 16) | 0;
      }
      if (
        this.hasText &&
        document.body.clientWidth >= parseInt(stylevars.mobileWidth)
      ) {
        return ((maxWidth / 2) | 0) + "px";
      } else {
        return maxWidth + "px";
      }
    },
    imageSrc() {
      if (this.image && this.image !== "") {
        return this.image;
      } else {
        if (this.matchYoutube(this.externalURL)) {
          let code = this.matchYoutube(this.externalURL)[1];
          return this.getYoutubeURL(code);
        }
        return this.externalURL;
      }
    },
  },
  mounted() {
    this.resizeAbortController = new AbortController();
    this.windowSize = window.innerWidth;
    requestAnimationFrame(() => {
      this.isMounted = true;
    });
    window.addEventListener(
      "resize",
      () => {
        if (Math.abs(window.innerWidth - this.windowSize) > 30) {
          this.imageLoaded = false; //forces a redraw of the components to resize iframes but only if the resize is more than 30px (so a scrollbar appearing doesnt trigger it)
          requestAnimationFrame(() => {
            this.imageLoaded = true;
          });
        }
        this.windowSize = window.innerWidth;
      },
      { signal: this.resizeAbortController.signal }
    );
  },
  unmounted() {
    this.resizeAbortController.abort();
  },
};
</script>

<style lang="scss" scoped>
.post-segment {
  display: flex;
  align-self: center;
  margin: $item-margin;
  border-radius: $border-radius;
  background-color: $grey-200;
  height: "fit-content";
  padding: 2px;
  overflow: hidden;
  &.with-image {
    &.with-text {
      .main-image-container {
        width: 50%;
      }
      .text-container {
        width: 50%;
        padding: 1rem;
        align-items: center;
      }
    }
    &:not(.with-text) {
      .main-image-container {
        width: 100%;
      }
    }
  }
  &.with-text {
    &:not(.with-image) {
      justify-content: center;
      padding: 1rem;
      .text-container {
        max-width: 75ch;
      }
    }
  }
}
.main-image-container {
  display: inline-block;
  position: relative;
  height: 100%;
}
.main-image {
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
}

.text-container {
  display: flex;
}
.text {
  color: $black;
}
.fullscreen {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  transform: none;
  transition: all 1s;
}
.backfill {
  z-index: 9998;
  background-color: rgba($black, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.main-text::v-deep > * > pre {
  overflow-x: scroll;
  margin: 0;
  max-width: 90vw;
  font-size: 1rem;
}

@media screen and (max-width: $switch-width) {
  .post-segment {
    flex-direction: column;
    &.with-image {
      &.with-text {
        .main-image-container {
          width: auto;
        }
        .text-container {
          width: auto;
        }
      }
    }
  }
  .text-container {
    justify-content: center;
    text-align: center;
  }
}
</style>
